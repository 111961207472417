<template>
  <div class="download-more container">
    <!-- list -->
    <ul class="list">
      <div class="title">
        <h3 class="title">{{downloadType}}</h3>
      </div>
      <li class="item" v-for="v in list" :key="v.id">
        <div class="item-title text-overflow-ellipsis" text-overflow-ellipsis>{{v.title}}</div>
        <div class="item-date">{{v.modifyTime|filterTime}}</div>
        <el-button type="text" icon="el-icon-download" @click="handleDownload(v)">下载</el-button>
      </li>
    </ul>

    <!-- 分页 -->
    <el-pagination
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
      @current-change="currentChange"
      @size-change="sizeChange"
      :page-sizes="[3, 5, 10, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
    ></el-pagination>
  </div>
</template>

<script>
import downloadSectionApi from "@/api/downloadSection";
import { mapState } from "vuex";

export default {
  mounted() {
    // 获取下载列表的类型
    this.downloadType = this.$route.params.downloadType;
    // 获取到类型之后再去获取list（因为不同type获取不同的list，list依赖于type）
    this.renderList();
  },
  data() {
    return {
      downloadType: "资料下载",
      list: [],

      // 分页相关参数
      currentPage: 1,
      pageSize: 10,
      total: 99
    };
  },
  computed: {
    ...mapState(["baseURL"])
  },
  filters: {
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD");
    }
  },
  methods: {
    // 分页相关函数
    currentChange(val) {
      this.currentPage = val;
      this.renderList();
    },
    sizeChange(val) {
      this.pageSize = val;
      this.renderList();
    },

    // 下载相关函数
    handleDownload(v) {
      window.open(this.baseURL + v.attachFilePath);
    },

    // 渲染相关函数(根据不同的downloadType请求不同的列表接口)
    async renderList() {
      // 获取资料下载列表
      if (this.downloadType == "资料下载") {
        let { code, data, message } = await downloadSectionApi.getDataList({
          page: this.currentPage,
          size: this.pageSize,
          sort: "asc",
          sortFiled: "sort"
        });
        this.list = data.records;
        this.total = data.total;
      }
      // 获取工具下载列表
      if (this.downloadType == "工具下载") {
        let { code, data, message } = await downloadSectionApi.getToolList({
          page: this.currentPage,
          size: this.pageSize,
          sort: "asc",
          sortFiled: "sort"
        });
        this.list = data.records;
        this.total = data.total;
      }
      // 获取其他下载列表
      if (this.downloadType == "其他下载") {
        let { code, data, message } = await downloadSectionApi.getElseList({
          page: this.currentPage,
          size: this.pageSize,
          sort: "asc",
          sortFiled: "sort"
        });
        this.list = data.records;
        this.total = data.total;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.download-more {
  // list
  .list {
    padding: 30px;
    // 标题
    .title {
      border-bottom: 1px solid #dcdcdc;
      margin-bottom: 20px;
      h3 {
        background: #3660d9;
        color: white;
        width: 120px;
        text-align: center;
        line-height: 30px;
        border: none; //防止遗传上级的 border-bottom: 1px solid #dcdcdc;
        margin: 0; //防止遗传上级的 margin-bottom: 30px;
      }
    }

    // item
    .item {
      display: flex;
      justify-content: space-between;
      line-height: 45px;
      .item-title {
        width: 760px;
      }
      .item-date {
        width: 120px;
        color: #979797;
        font-size: 14px;
      }
    }
  }

  // 分页
  .el-pagination {
    text-align: center;
    line-height: 60px;
  }
}
</style>